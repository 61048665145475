import { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { lazy } from "react";
import ScrollToTop from "./utils/functions/ScrollToTop";
import Loading from "./components/common/Loading";

// Lazy-loaded components with confirmed paths and default export setup
const HomePage = lazy(() => import("./pages/homepage"));
const AdminAddProduct = lazy(
  () => import("./pages/adminPortal/adminAddProduct/addProduct")
);
const AdminProductCatelog = lazy(
  () => import("./pages/adminPortal/adminProductCatelog/adminProductList")
);
const AdminOrderList = lazy(
  () => import("./pages/adminPortal/adminOrderList/adminOrderList")
);
const AllProducts = lazy(() => import("./pages/AllProducts"));
const AdminEditProduct = lazy(
  () => import("./pages/adminPortal/adminEditProduct/editProduct")
);
const ProductDetail = lazy(() => import("./pages/ProductDetail"));
const OrderCart = lazy(() => import("./pages/OrderCart"));
const OrderHistory = lazy(() => import("./pages/OrderHistory"));
const ProductCategory = lazy(() => import("./pages/ProductCategory"));

export function Router() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback={<Loading isLoading />}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/all-products" element={<AllProducts />} />
          <Route path="/add-products" element={<AdminAddProduct />} />
          <Route path="/products" element={<AdminProductCatelog />} />
          <Route
            path="/edit-product/:productId"
            element={<AdminEditProduct />}
          />
          <Route path="/order-list" element={<AdminOrderList />} />
          <Route path="/product-detail/:id" element={<ProductDetail />} />
          <Route path="/order-confirm" element={<OrderCart />} />
          <Route path="/order-history" element={<OrderHistory />} />
          <Route path="/category/:catId" element={<ProductCategory />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
