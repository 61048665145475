import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAllOrdersAPI,
  updateOrderStatusAPI,
} from "../../services/orderService";

export interface Order {
  orderId: string;
  userId: string;
  productCount: number;
  employeeName: string; // Employee name added
  orderDate: string;
  deliveredDate?: string | null;
  orderStatus?: string;
  totalPrice: number; // Renamed to match API field
  shippingAddress: string;
  billingAddress: string;
  items: {
    productId: string;
    quantity: string;
    pricePerUnit: number;
    itemTotalPrice: number;
  }[];
}

interface OrdersState {
  orders: Order[];
  loading: boolean;
  error: string | null;
}

const initialState: OrdersState = {
  orders: [],
  loading: false,
  error: null,
};

/// Async thunk to fetch orders
export const fetchAllOrders = createAsyncThunk(
  "allOrders/fetchAllOrders",
  async () => {
    const response = await getAllOrdersAPI();
    return response.data.orders; // Access orders array specifically
  }
);

export const fetchAllOrdersSilent = createAsyncThunk(
  "allOrders/fetchAllOrdersSilent",
  async () => {
    const response = await getAllOrdersAPI();
    return response.data.orders; // Access orders array specifically
  }
);

export const orderStatusUpdate = createAsyncThunk(
  "orderUpdate/orderStatusUpdate",
  async ({ orderId, newStatus }: { orderId: string; newStatus: string }) => {
    const data = await updateOrderStatusAPI(orderId, newStatus);
    return { orderId, newStatus, data }; // Return orderId and newStatus with data
  }
);

const allOrdersSlice = createSlice({
  name: "allOrders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchAllOrders.fulfilled,
        (state, action: PayloadAction<Order[]>) => {
          state.loading = false;
          state.orders = action.payload;
        }
      )
      .addCase(fetchAllOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch orders";
      })
      .addCase(fetchAllOrdersSilent.pending, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(
        fetchAllOrdersSilent.fulfilled,
        (state, action: PayloadAction<Order[]>) => {
          state.loading = false;
          state.orders = action.payload;
        }
      )
      .addCase(fetchAllOrdersSilent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch orders";
      })
      .addCase(orderStatusUpdate.fulfilled, (state, action) => {
        const { orderId, newStatus } = action.payload;

        // Find the specific order by orderId and update its orderStatus
        const order = state.orders.find((order) => order.orderId === orderId);
        if (order) {
          order.orderStatus = newStatus;
        }
      });
  },
});

export default allOrdersSlice.reducer;
