import React, { useEffect, useState, useCallback } from "react";
import { Router } from "./Router";
import { ThemeProvider } from "@mui/material/styles";
import { useMsal } from "@azure/msal-react";
import { useAppDispatch } from "./hooks/reduxHooks";
import { getUser, getUserFeatures } from "./store/slices/userSlice";
import Loading from "./components/common/Loading";
import {
  BrowserAuthError,
  InteractionStatus,
  EventType,
  AuthenticationResult,
} from "@azure/msal-browser";
import { fetchAllOrders } from "./store/slices/allOrderSlice";
import { fetchCategories } from "./store/slices/categorySlice";
import { fetchProducts } from "./store/slices/productsSlice";
import customTheme from './theme';
import { GlobalStyles } from '@mui/system';


const App: React.FC = () => {
  const { instance, accounts, inProgress } = useMsal();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isAuthCompleted, setIsAuthCompleted] = useState(false);
  const dispatch = useAppDispatch();

  // Move loadAppData to useCallback
  const loadAppData = useCallback(async () => {
    try {
      await Promise.all([
        dispatch(getUser()),
        dispatch(getUserFeatures()),
        dispatch(fetchCategories()),
        dispatch(fetchAllOrders()),
        dispatch(fetchProducts()),
      ]);
      setIsInitialized(true);
    } catch (error) {
      console.error("Error loading app data:", error);
      // Handle error appropriately
    }
  }, [dispatch]); // Add dispatch as dependency

  // Handle authentication events
  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        const result = event.payload as AuthenticationResult;
        instance.setActiveAccount(result.account);
        setIsAuthCompleted(true);
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  // Handle initialization and authentication
  useEffect(() => {
    const initializeApp = async () => {
      try {
        await instance.initialize();
        const result = await instance.handleRedirectPromise();

        if (result) {
          console.log(result);
          // Redirect login completed
          setIsAuthCompleted(true);
          return;
        }

        if (accounts.length === 0 && inProgress === InteractionStatus.None) {
          try {
            await instance.loginRedirect({
              scopes: [
                `api://${process.env.REACT_APP_API_CLIENT_ID}/access_as_user`,
              ],
            });
          } catch (error) {
            if (
              error instanceof BrowserAuthError &&
              error.errorCode === "interaction_in_progress"
            ) {
              console.log("Login already in progress");
              return;
            }
            throw error;
          }
        } else if (accounts.length > 0) {
          setIsAuthCompleted(true);
        }
      } catch (error) {
        console.error("Initialization error:", error);
      }
    };

    initializeApp();
  }, [instance, accounts, inProgress]);

  // Handle data loading after authentication is complete
  useEffect(() => {
    if (isAuthCompleted && accounts.length > 0 && !isInitialized) {
      loadAppData();
    }
  }, [isAuthCompleted, accounts, isInitialized, loadAppData]); // Added loadAppData to dependencies

  // Show loading while initializing or during authentication
  if (!isInitialized || inProgress !== InteractionStatus.None) {
    return <Loading isLoading />;
  }

  // Render app once initialized
  return (
    <ThemeProvider theme={customTheme}>
      <GlobalStyles
        styles={{
          '.base-Popup-root.base-Menu-root': {
            zIndex: 2,
          },
        }}
      />
      <div className="App">
        <Router />
      </div>
    </ThemeProvider>
  );
};

export default App;
